import React from "react"
import styled from 'styled-components'
import Header from "../components/layout/Header"
import Footer from '../components/layout/Footer'
import Link from "@material-ui/core/Link"
import MainWrapper from '../components/layout/Main'
import Grid from "@material-ui/core/Grid"

const Root = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
`

const Paragraph = styled.p`
    text-align: center;
`

function Main ({ classes }) {
    
    return (
        <div className="outter_wrapper">
        
        <Root>
            <Header pageTitle='Home' />
            <MainWrapper>
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <h1> Jean Loureiro</h1>
                        <h2>front end developer</h2>
                        <Paragraph><Link href="https://twitter.com/search?q=%23100DaysOfCode" target='_blank' rel="noopener noreferrer">
                            #100DaysOfCode</Link> Challenge is on</Paragraph>
                    </Grid>
                </Grid>
            </MainWrapper>
            <Footer />
        </Root>
        </div>
    )
}

export default Main